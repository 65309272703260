import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

function App() {
  const { unityProvider } = useUnityContext({
    loaderUrl: "BuildWebGL/Build/webgl-after-unity.loader.js",
    dataUrl: "BuildWebGL/Build/webgl-after-unity.data.unityweb",
    frameworkUrl: "BuildWebGL/Build/webgl-after-unity.framework.js.unityweb",
    codeUrl: "BuildWebGL/Build/webgl-after-unity.wasm.unityweb",
  });

  return <Unity unityProvider={unityProvider} style={{ width: 800, height: 600 }} />;
}

export default App;
